import { IconPalette, IconShadow, IconWindmill, IconTrash, IconDashboard, IconPlus, IconChalkboard, IconSettings , IconUsers, IconHome2, IconArrowAutofitDown } from '@tabler/icons';
// constant
const icons = {
  IconPlus,
  IconDashboard,
  IconChalkboard,
  IconTrash,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconSettings,
  IconUsers,
  IconHome2,
  IconArrowAutofitDown
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'default',
  title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'school',
      title: 'School',
      type: 'collapse',
      icon: icons.IconHome2,
      children: [
        {
          id: 'view-schools',
          title: 'View Schools',
          type: 'item',
          url: '/view-schools',
          icon: icons.IconChalkboard,
          breadcrumbs: false
        },
        {
          id: 'add-school',
          title: 'Add School',
          type: 'item',
          url: '/add-school',
          icon: icons.IconPlus,
          breadcrumbs: false
        },
        {
          id: 'delete-school',
          title: 'Delete School',
          type: 'item',
          url: '/delete-school',
          icon: icons.IconTrash,
          breadcrumbs: false
        },
        {
          id: 'school-groups',
          title: 'School Groups',
          type: 'item',
          url: '/school-groups',
          icon: icons.IconUsers,
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'trialrequest',
      title: 'Trial Requests',
      type: 'item',
      url: '/trial-requests',
      icon: icons.IconArrowAutofitDown,
      breadcrumbs: false
    },
  ]
};

export default dashboard;
