import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const AddSchool = Loadable(lazy(() => import('views/pages/AddSchool')));
const DeleteSchool = Loadable(lazy(() => import('views/pages/DeleteSchool')));
const ViewSchools = Loadable(lazy(() => import('views/pages/ViewSchools')));
const DemoRequest = Loadable(lazy(() => import('views/pages/DemoRequest')));
const SchoolsGroups = Loadable(lazy(() => import('views/pages/SchoolGroups')));
const Settings = Loadable(lazy(() => import('views/pages/Settings')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: 'view-schools',
      element: <ViewSchools />
    },
    {
      path: 'add-school',
      element: <AddSchool />
    },
    {
      path: 'add-school/:id',
      element: <AddSchool />
    },
    {
      path: 'delete-school',
      element: <DeleteSchool />
    },
    {
      path: 'school-groups',
      element: <SchoolsGroups />
    },
    {
      path: 'trial-requests',
      element: <DemoRequest />
    }
  ]
};

export default MainRoutes;
