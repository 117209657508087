import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const DemoPage = Loadable(lazy(() => import('views/pages/Demo/DemoPage')));

// ==============================|| MAIN ROUTING ||============================== //

const SecondaryRoute = {
  path: '/request-trial',
  element: <DemoPage />
};

export default SecondaryRoute;
