import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from 'routes';
import themes from 'themes';
import NavigationScroll from 'layout/NavigationScroll';
import { verifyTokenAPI } from 'api/auth/verify';
import { setUser } from 'store/actions';

const App = () => {
  const customization = useSelector((state) => state.customization);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem('token'));
  useEffect(() => {
    const verifyUser = async () => {
      if (!token) {
        dispatch(setUser(false));
        navigate('/login');
      } else {
        try {
          const verified = await verifyTokenAPI(token);
          if (!verified) {
            localStorage.removeItem('token');
            dispatch(setUser(false));
            navigate('/login');
          } else {
            dispatch(setUser(true));
            if (location.pathname === '/login') {
              navigate('/');
            }
          }
        } catch {
          localStorage.removeItem('token');
          dispatch(setUser(false));
          navigate('/login');
        }
      }
    };

    verifyUser();
  }, [dispatch, token]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
      </ThemeProvider>
      <ToastContainer />
    </StyledEngineProvider>
  );
};

export default App;
