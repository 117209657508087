// material-ui
import logo from 'assets/images/logo-dark.png';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
import 'assets';
     *
     *
     */
    <img src={logo} alt="Berry" width="100" />
  );
};

export default Logo;
