import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import SecondaryRoute from './SecondaryRoute';
// ==============================|| ROUTING RENDER ||============================== //
import { useSelector } from 'react-redux';

export default function ThemeRoutes() {
  const user = useSelector((state) => state.customization?.loggedIn);
  if (user) {
    return useRoutes([MainRoutes, AuthenticationRoutes, SecondaryRoute]);
  } else {
    return useRoutes([AuthenticationRoutes]);
  }
}
