import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const verifyTokenAPI = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/schools/verify`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.verified;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};
